body {
  margin: 0;
  font-family: 'Noto Sans CJK TC', -apple-system, BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-tooltip {
  font-size: 11px;
  border-radius: 4px;
}

.ant-tooltip-inner {
  box-shadow: none;
  position: relative;
  /* top: -5px; */
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
}

.newNotification .anticon.ant-notification-notice-icon-success {
  color: #4caf50;
}

.newNotification .anticon.ant-notification-notice-icon-error {
  color: #f44336;
}

.newNotification .anticon.ant-notification-notice-icon-warning {
  color: #ff9800;
}

.newNotification .anticon.ant-notification-notice-icon-success svg,
.newNotification .anticon.ant-notification-notice-icon-error svg,
.newNotification .anticon.ant-notification-notice-icon-warning svg {
  border-radius: 50%;
  background: #fff;
}

.newNotification .ant-notification-notice-with-icon .ant-notification-notice-message {
  color: #fff;
}

.newNotification .ant-notification-notice-close {
  color: #fff;
}

.mobileScrollingStop{
  touch-action: none;
  overflow: hidden;
}